'use strict';

const swiper = new Swiper('.swiper-container', {
    loop: true,

    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

    scrollbar: {
        el: '.swiper-scrollbar',
    },
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
});

const swiperPerview = new Swiper('.swiper-perview-container', {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    // Responsive breakpoints
    breakpoints: {
        // ketika window width >= 320px
        320: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        // ketika window width >= 480px
        480: {
            slidesPerView: 2,
            spaceBetween: 20
        },
        // ketika window width >= 768px
        768: {
            slidesPerView: 3,
            spaceBetween: 30
        }
    }
});